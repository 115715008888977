<template>
  <user-chip-logs-card />
</template>
<script>
import UserChipLogsCard from "./components/UserChipLogsCard.vue";
export default {
  components: {
    UserChipLogsCard,
  },
};
</script>
