<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>User Chip Logs</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field v-model="search" label="UserId" single-line v-on:keyup.enter="initialize" hide-details></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates"
        transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="dates" label="Picker in menu" prepend-icon="mdi-calendar" readonly single-line
            hide-details v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="dates" locale="tr-TR" range no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="initialize">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox v-model="pageItemSelect" :items="pageItems" item-value="id" item-text="value" @change="initialize"
        hide-details label="Page"></v-combobox>
      <v-btn color="primary" dark @click="initialize" class="mb-2 ma-2">Search</v-btn>
    </v-toolbar>

    <v-data-table :headers="userChipAggregateDataTable" v-if="$store.state.userChipLogs.all.data.uca" :items="$store.state.userChipLogs.all.data.uca">
      <template v-slot:item.key="{ item }" >
        <div v-bind:style="{ color:'orange'}">{{bonusType.find(x => x.key === item.key)?.value}}</div>
      </template>
      <template v-slot:item.totalChip.value="{ item }">
        <div v-bind:style="{ color: item.key===3||item.key===25 ?'#FF6D6D':'lime'}">{{ item.totalChip.value |formatMoney}}</div>
      </template>
      <br>
      <hr/>
    </v-data-table>
    
  
    <v-data-table :headers="userChipLogsDataTable"  :items="$store.state.userChipLogs.all.data.ucl"
      :items-per-page="pageItemSelect.id" hide-default-footer :loading="loading" :options.sync="options"
      loading-text="Loading... Please wait" class="elevation-1">
      
      <template v-slot:item.u="{item}">
        <UserDetailCard
          :userId="item.u.i"
          :pictureUrl="item.u.p"
          :userName="item.u.n"
          :level="item.u.l"
        />
      </template>

      <template v-slot:item.t="{ item }" >
        <div v-bind:style="{ color:'orange'}">{{bonusType.find(x => x.key === item.t)?.value}}</div>
      </template>
      <template v-slot:item.c="{ item }">
        <div v-bind:style="{ color: item.icn?'lime':'#FF6D6D'}">{{ item.c | formatMoney }}</div>
      </template>
      <template v-slot:item.icn="{ item }">
        <div v-bind:style="{ color: item.icn ?'lime':'#FF6D6D'}">{{ item.icn?"Chip In":"Chip Out" }}</div>
      </template>

      <template v-slot:item.createdDate="{ item }">
        <div>{{ unixTimestampToFormattedDate(item.createdDate)}}</div>
      </template>

      <template v-slot:item.rg="{ item }">
        <div>{{ unixTimestampToFormattedDate(item.rg)}}</div>
      </template>
      <template v-slot:item.d="{ item }">
        <v-icon small @click="dataDetail(item)">mdiJson</v-icon>
      </template>
  
    </v-data-table>
    <v-dialog v-model="dialogDetailDataSetting" max-width="1600px">
      <v-card>
        <v-card-title>
          <span class="headline">Detail Data</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDetailDataSetting = false"
            >Close</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <json-viewer
            name="df"
            :value="dialogDetailDataSetting"
            :expand-depth="5"
            theme="my-awesome-json-theme"
            sort
          ></json-viewer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="text-center pt-2">
      <v-pagination v-model="currentPage" @input="pageClick" :total-visible="20"
        :length="$store.state.userChipLogs.all.totalPage"></v-pagination>
    </div>
  </div>
</template>
<script>
import "@/assets/css/site.css";
import bonusType from "../../../lib/userChipBonusTypes";

export default {
  components: {
    UserDetailCard: () => import("@/components/Users/UserDetailCard.vue"),
  },
  name: "UserChipLogsCard",
  props: {
    search: {
      type: String,
    },
  },
  allData:[],
  data() {
    return {
      dates: [
        new Date(Date.now())
          .addDays(-30)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now()).toISOString().substr(0, 10),
      ],
      menu: false,
      dialog: false,
      dialogDetailDataSetting: false,
      detailData:{},
      pageCount: 0,
      typeSelect: { key: -999, value: "All" },
      pageItemSelect: { id: 25, value: "25" },
      loading: false,
      currentPage: 1,
      bonusType: bonusType,
      options: {},
      pagination: {
        sortBy: "name",
      },
      pageItems: [
        { id: 25, value: "25" },
        { id: 50, value: "50" },
        { id: 100, value: "100" },
        { id: 250, value: "250" },
        { id: 500, value: "500" },
        { id: 1000, value: "1000" },
      ],
      userChipLogsDataTable: [
        { text: "User", value: "u" },
        { text: "Register Date", value: "rg" },
        { text: "Last Spin", value: "createdDate" },
        { text: "Level", value: "l" },
        { text: "Chip", value: "c" },
        { text: "IsChipIn", value: "icn" },
        { text: "Source", value: "t" },
        { text: "Detail Data", value: "d" }
      ],
      userChipAggregateDataTable: [
        { text: "Source", value: "key" },
        { text: "Document Count", value: "doc_count" },
        { text: "Chip", value: "totalChip.value" }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        if (this.options.sortBy.length > 0) this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
    async dataDetail(item) {
      this.detailData = item.ss;
      this.dialogDetailDataSetting= true;
    },
    async initialize() {
      //this.$refs.menu.save(this.dates);
      this.loading = true;
      await this.$store.dispatch("userChipLogs/getAll", {
        userId: this.search,
        startDate:
          new Date(
            new Date(this.dates[0]).getTime() +
            new Date().getTimezoneOffset() * 60000
          ) / 1000,
        endDate: new Date(this.dates[1]).addDays(1).getTime() / 1000,
        size: this.pageItemSelect.id,
        page: this.currentPage - 1,
      });
      // eslint-disable-next-line no-console
      console.log("bonusType= ",bonusType);
      this.loading = false;
    },
    
    unixTimestampToFormattedDate(unixTimestamp) {
    var date = new Date(unixTimestamp * 1000); // Unix zaman damgasını milisaniyeye çevir
    var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    var formattedDate = new Intl.DateTimeFormat('tr-TR', options).format(date);
    return formattedDate;
    
    //GG/AA/YYYY formatında döndürüyor
    /*
    var date = new Date(unixTimestamp * 1000); // Unix zaman damgasını milisaniyeye çevir
    var day = String(date.getDate()).padStart(2, '0'); // Günü al ve iki haneli yap
    var month = String(date.getMonth() + 1).padStart(2, '0'); // Ayı al (0 ile başladığı için +1 ekleyip), iki haneli yap
    var year = date.getFullYear(); // Yılı al

    return `${day}/${month}/${year}`;
    */
  }
  },

  async mounted() {
    await this.initialize();
  },

  
};

</script>

<style>
.card-p {
  width: 142px;
  height: 155px;
  background-size: 142px 155px;
  background-repeat: no-repeat;
  border-radius: 9.5px;
  margin-right: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.lime-card {
  background-image: url(https://bundle.slotbase.net/assets/img/green.png);
}

.green-card {
  background-image: url(https://bundle.slotbase.net/assets/img/green.png);
}

.red-card {
  background-image: url(https://bundle.slotbase.net/assets/img/red.png);
}

.orange-card {
  background-image: url(https://bundle.slotbase.net/assets/img/orange.png);
}

.v-tooltip__content {
  opacity: 1 !important;
}

.jv-container {
  background: #0000005c !important;
}

.jsoneditor-field {
  color: #b7b3c3 !important;
}

a.jsoneditor-value.jsoneditor-url,
div.jsoneditor-value.jsoneditor-url {
  color: #00cfff !important;
}

div.jsoneditor-value.jsoneditor-array,
div.jsoneditor-value.jsoneditor-object {
  color: #b7abab !important;
}

.ace-jsoneditor .ace_variable,
.ace-jsoneditor .ace_gutter-cell {
  color: #fff !important;
}

div.jsoneditor-value.jsoneditor-string {
  color: #00cfff;
}

.ace-jsoneditor .ace_scroller,
.ace-jsoneditor .ace_gutter {
  background: #000 !important;
}
</style>
