export default [
  { value: "DailyBonus", key: 0 },
  { value: "LuckyBonus", key: 1 },
  { value: "ModeWinChip", key: 2 },
  { value: "ModeLostChip", key: 3 },
  { value: "InGame", key: 4 },
  { value: "Gift", key: 5 },
  { value: "AcceptGiftBonus", key: 6 },
  { value: "GiftBox", key: 7 },
  { value: "NotificationBonus", key: 8 },
  { value: "ProductBonus", key: 9 },
  { value: "SafetyNet", key: 10 },
  { value: "VideoBonus", key: 11 },
  { value: "WeeklyBonus", key: 12 },
  { value: "WelcomeBonus", key: 13 },
  { value: "Piggy", key: 14 },
  { value: "WinnerPool", key: 15 },
  { value: "ComeBackBonus", key: 16 },
  { value: "MiniGame", key: 17 },
  { value: "Mission", key: 18 },
  { value: "Purchase", key: 19 },
  { value: "FirstTimeBonus", key: 20 },
  { value: "FeedBackBonus", key: 21 },
  { value: "LevelWin", key: 22 },
  { value: "LevelMap", key: 23 },
  { value: "OutOfCoin", key: 24 },
  { value: "PurchaseFreeSpinOnMode", key: 25 },
  { value: "SelectionPanel", key: 26 }
];
